import React from 'react';
import { Button } from 'reactstrap';
import { useModal } from '../../../../context/modal/ModalComponent';
import { useUser } from '../../../../context/user/UserContext';
import { getRoom } from '../../../../utils/variables';
import { useGetWatchlist } from '../../../../services/Threat/getWatchlist';
import { RequestInvestigation } from '../../../../components/RequestInvestigation';
import { ThreatIdentifier } from '../../../../components/CommonModals/ThreatIdentifier';
import { NotThreatIdentifierModal } from '../../../../components/CommonModals/NotThreatIdentifier';
import { WatchlistButtonContainer } from './WatchlistButtonContainer';
import { ContentTableContentType } from '../../../../components/Organisms/ContentTableView';
import { WATCHLIST_BUTTON_TYPES } from './WatchlistButton';

type ThreatLabelProps = {
    threatLevel: string,
    threatLabels: string []
}

type ContentType = {
    id: string,
    threatObject: ThreatLabelProps
}

type ContentActionsDropdownProps = {
    content: ContentType
}

export const ActionButtons = ({
    content,
}: ContentActionsDropdownProps) => {
    const room = getRoom();
    const { notAllowed }: any = useUser();
    const { setModal, closeModal } = useModal();
    const instancePlan = room.instance?.plan;
    const { watchlist, refetchWatchlist } = useGetWatchlist({
        situationRoomId: room.id
    });
    const isWatching = watchlist ? watchlist.filter(({ id }: {id: string}) => id === content.id).length > 0 : false;

    if (notAllowed.includes('editData') || !instancePlan?.others?.contentActions) return null;

    const setRequestInvestigationModal = () => setModal({
        modal: <RequestInvestigation defaultDetails={content} onClose={closeModal} />,
        goToPreviousOnClose: true
    });

    const setMarkAsThreatModal = () => setModal({
        modal: <ThreatIdentifier onClose={closeModal} threatId={content.id} />,
        goToPreviousOnClose: true
    });

    const setNotThreatIdentifierModal = () => {
        setModal({
            modal: <NotThreatIdentifierModal ids={[content.id]} />,
            goToPreviousOnClose: true
        });
    };
    return (
        <>
            <div className="d-flex mb-3">
                <h3 className="m-0">Actions</h3>
            </div>
            {content.threatObject?.threatLevel !== 'none' ? (
                <div className="d-flex">
                    <WatchlistButtonContainer contents={[content as ContentTableContentType]}
                        isWatching={isWatching}
                        onCompleted={refetchWatchlist}
                        type={WATCHLIST_BUTTON_TYPES.BUTTON}
                        className="mr-2"
                    />
                    <Button color="secondary" className="mr-2" onClick={setNotThreatIdentifierModal}>Mark as not threat</Button>
                    <Button color="secondary" onClick={setRequestInvestigationModal}>Request investigation</Button>
                </div>
            ) : <Button color="secondary" className="mr-2" onClick={setMarkAsThreatModal}>Mark as threat</Button>}
        </>
    );
};

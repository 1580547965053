import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { WATCHLIST_BUTTON_TYPES } from '../../pages/Room/Threats/Threat/WatchlistButton';
import { WatchlistButtonContainer, WatchlistButtonContentKeys } from '../../pages/Room/Threats/Threat/WatchlistButtonContainer';
import { Content as ContentType } from '../../services/Content/contentInterface';
import { useQueryExtracts } from '../../services/Content/getQueryExtracts';
import { useTranslateMultipleText } from '../../services/google/translate';
import { useGetWatchlist } from '../../services/Threat/getWatchlist';
import { getRoom } from '../../utils/variables';
import { TranslateButton } from '../Atoms/TranslateButton';
import { ContentActionsDropdown } from './ContentActionsDropdown';
import { ContentText } from './ContentText';
import { LabelsDisplay } from '../../pages/Room/Labels/LabelsDisplay';
import { LabelDropdown } from '../../pages/Room/Labels';

type ContentKeys = 'body' | WatchlistButtonContentKeys;

type RoomPreviewKeywords = {
    highlightKeywords: string[]
}

type ContentProps = {
    content: Pick<ContentType, ContentKeys>,
    noActions: boolean
    isClusterData?: boolean
}

export const Content = ({ content, noActions, isClusterData }: ContentProps) => {
    const room = getRoom();
    const location = useLocation();
    const [showLanguages, setShowLangauges] = useState(false);
    const { highlightKeywords } = useSelector((state: {roomPreviewKeywords: RoomPreviewKeywords}) => state.roomPreviewKeywords);

    const { watchlist, refetchWatchlist } = useGetWatchlist({
        situationRoomId: room.id
    });
    const isWatching = watchlist ? watchlist.filter(({ id }: {id: string}) => id === content.id).length > 0 : false;

    const textLimit = 500;

    const contentText = content?.body?.length > 2 ? content.body : content.text;
    const passKeywords = location.pathname.includes('create-room') || location.pathname.includes('edit-room')
    || location.pathname.includes('edit-draft-room');
    const { highlightedText } = useQueryExtracts({
        url: content.url,
        text: contentText,
        keywordsToHighlight: passKeywords ? highlightKeywords : []
    });
    const { getTranslation, data: translated, loading: translateLoading } = useTranslateMultipleText({
        text: highlightedText
    });
    const finalText = translated.length ? translated[0].translations[0] : highlightedText;
    const translation = (e: MouseEvent) => {
        e.stopPropagation();
        e.preventDefault();
        getTranslation();
        setShowLangauges(!showLanguages);
    };
    const contentActionsEnabled = room.instance?.plan?.others?.contentActions;

    return (
        <div>
            <div className="d-flex justify-content-end align-items-center content-top-bar">
                {(content?.threatObject?.threatLevel !== 'none' && !noActions) && (
                    <WatchlistButtonContainer contents={[content]}
                        isWatching={isWatching}
                        onCompleted={refetchWatchlist}
                        type={WATCHLIST_BUTTON_TYPES.TEXT_BUTTON}
                    />
                )}
                <TranslateButton className="ml-2 d-inline-block my-n2"
                    onClick={translation}
                    lang={content?.langVerbose}
                    detectedLangs={content?.detectedLangs}
                    loading={translateLoading}
                    showText
                />
                <LabelDropdown contentId={content.id} roomId={room?.id} />
                {(!noActions && contentActionsEnabled && !isClusterData) && <ContentActionsDropdown content={content} />}
            </div>
            <hr className="ml-n3" />
            <LabelsDisplay contentId={content.id} roomId={room?.id} />
            <ContentText text={finalText}
                textLimit={textLimit}
                showLanguages={showLanguages}
                langVerbose={content?.langVerbose}
                translateLoading={translateLoading}
                detectedLangs={content?.detectedLangs}
            />
        </div>
    );
};

import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { allTypes } from '../../../utils/lib/search';
import { RootState } from '../../../store';
import { saveSelectedFilters } from '../../../pages/Room/store';
import { DropdownWithMultiCheckbox, ItemProps } from '../components/DropdownWIthMultiCheckbox';
import { getRoom } from '../../../utils/variables';

export const ThreatTypesFilter = () => {
    const dispatch = useDispatch();
    const { threatTypes: selectedThreatTypes }: { threatTypes: string[] } = useSelector((state:
         RootState) => state.selectedFilters.filters);
    const room = getRoom();
    const disabledThreatTypes = room.instance?.plan?.disabledThreatTypes || [];
    const threatTypesData = allTypes.map(({ name, key }: {name: string, key: string}) => ({
        id: key,
        name,
        selected: selectedThreatTypes.includes(key)
    })).filter(({ id }) => !disabledThreatTypes.includes(id))
        .sort((a: ItemProps, b: ItemProps) => a.name.localeCompare(b.name));
    const handleSelectItem = (ids: string[]) => {
        dispatch(saveSelectedFilters({
            threatTypes: ids
        }));
    };

    const countFilters = selectedThreatTypes.length;

    return (
        <div>
            <DropdownWithMultiCheckbox value="threatTypes"
                name={countFilters > 0
                    ? `${countFilters} option${countFilters > 1 ? 's...' : ''}` : 'Select options'}
                filterOptions={threatTypesData}
                handleSelectItem={handleSelectItem}
                threat
            />
        </div>

    );
};

import React from 'react';
import { PlusSquare, X } from 'react-feather';
import { Button } from 'reactstrap';
import { useDispatch, useSelector } from 'react-redux';
import { Cluster, SubCluster } from '../../types';
import { useModal } from '../../../../../context/modal/ModalComponent';
import { getParam, setParam } from '../../../../../utils/urlParams';
import { getToast } from '../../../../../utils/getToast';
import { AddNarrativeModal } from './AddNarrativeModal';
import { RootState } from '../../../../../store';
import { resetSelectedCluster, setNarrativesComparisonList, setNarrativesThemeComparisonList } from '../../../store';
import { AddNarrativeThemeModal } from './AddNarrativeThemeModal';
import { useScrollPosition } from '..';
import { Loading } from '../../../../../components/Loading';

type SelectedItemProps = {
    clusterData: SubCluster[] | Cluster[]
    isParent: boolean
}
type SelectedNarrativesType = {
    id: string
    title: string
}

export const SelectedItems = ({ clusterData, isParent }: SelectedItemProps) => {
    const { setModal, closeModal } = useModal();
    const toast = getToast();
    const dispatch = useDispatch();
    const ids = getParam('id')?.split(',');
    const { selectedNarrativeThemes } = useSelector((state: RootState) => state.narrativeThemeComparison);
    const { selectedNarratives } = useSelector((state: RootState) => state.narrativeComparison);
    const narrativeColors = ['square-small-blue', 'square-small-purple', 'square-small-green', 'square-small-orange'];
    const viewSummaryModal = (title: string, summary: string) => setModal({
        component: (
            <div>
                <h3 className="m-0">Narrative{isParent && ' theme'} details</h3>
                <hr />
                <p className="font-weight-bold">{title}</p>
                <p className="my-2">{summary}</p>
                <hr />
                <Button onClick={closeModal}>Close</Button>
            </div>
        )
    });
    const removeFromComparison = (id: string) => {
        if (clusterData.length < 3) {
            toast.error(`Atleast 2 Narrative${isParent
                ? ' theme' : ''}s are required for comparing, can't remove Narrative${isParent ? ' theme' : ''}.`);
        }
        else {
            toast.success(`Narrative${isParent ? ' theme' : ''} removed successfully`);
            const filteredIds = ids?.filter(x => x !== id);
            setParam('id', filteredIds);
            if (!isParent) {
                const selectedItems = selectedNarratives.filter((narrative: SelectedNarrativesType) => narrative.id !== id);
                dispatch(setNarrativesComparisonList({
                    selectedNarratives: selectedItems
                }));
                return;
            }
            const selectedThemes = selectedNarrativeThemes.filter((theme: SelectedNarrativesType) => theme.id !== id);
            dispatch(setNarrativesThemeComparisonList({
                selectedNarrativeThemes: selectedThemes
            }));
        }
    };
    const onClickAddNarrative = () => {
        dispatch(resetSelectedCluster());
        if (!isParent) {
            setModal({
                size: 'xl',
                component: <AddNarrativeModal />
            });
        }
        else {
            setModal({
                size: 'xl',
                component: <AddNarrativeThemeModal />
            });
        }
    };
    const isSticky = useScrollPosition({
        targetScroll: 370
    });
    const selected = isParent ? selectedNarrativeThemes : selectedNarratives;
    return (
        <>
            <p className="font-weight-bold mb-4">Selected items</p>
            {isSticky && <div className="p-3" />}
            <div className={`grid_container_narrative mb-4 fixed-to-top ${isSticky ? 'shadow-light' : ''}`}>
                {
                    [0, 1, 2, 3]?.map((_, index) => {
                        if (clusterData[index]) {
                            const title = isParent ? (clusterData[index] as Cluster).title
                                : (clusterData[index] as SubCluster).subClusterTitle;
                            const summary = isParent ? (clusterData[index] as Cluster).description
                                : (clusterData[index] as SubCluster).subClusterSummary;
                            return (
                                <div className="border bg-white p-2" key={clusterData[index].id}>
                                    <div className="d-flex align-items-center justify-content-between">
                                        <div className="d-flex align-items-center">
                                            <div className={narrativeColors[index]} />
                                            <p className="m-0 pl-1">Narrative {isParent && 'theme'} {index + 1}</p>
                                        </div>
                                        <X className="cursor-pointer"
                                            color="#006FF9"
                                            onClick={() => removeFromComparison(clusterData[index].id)}
                                            data-testid="removeFromComparison"
                                        />
                                    </div>
                                    <hr className="my-2" />
                                    <p className={isSticky ? 'mb-0' : 'mb-5'}>{title}</p>
                                    {!isSticky && (
                                        <Button color="link"
                                            className="summary-button"
                                            onClick={() => viewSummaryModal(title, summary)}
                                        >
                                            View summary
                                        </Button>
                                    )}
                                </div>
                            );
                        }
                        return (
                            <div key={_}
                                className="border text-primary cursor-pointer bg-lightgray
                            d-flex align-items-center justify-content-center"
                            >
                                {selected[index] ? <Loading relative /> : (
                                    <div className="p-4 d-flex align-items-center" onClick={onClickAddNarrative}>
                                        <PlusSquare className="mr-1" /> Add a narrative {isParent && 'theme'}
                                    </div>
                                )}
                            </div>
                        );
                    })
                }
            </div>
        </>
    );
};

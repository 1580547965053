import React, { ReactNode } from 'react';
import { Col, Row } from 'reactstrap';
import { X } from 'react-feather';
import { useLocation } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { ContentTableContentType } from './index';
import { useQueryExtracts } from '../../../services/Content/getQueryExtracts';
import { TooltipItem } from '../../ToolTip';
import { KeywordsModal } from '../../ActivityItem/KeywordsModal';
import { useModal } from '../../../context/modal/ModalComponent';
import { color } from '../../../utils/getColors';

type ContentTableContextProps = {
    content: ContentTableContentType
    engagementExpanded: boolean
    keywordsExpanded: boolean
    onClose: () => void
}

type EngagementsProps = {
    content: ContentTableContentType
}

type RoomPreviewKeywords = {
    highlightKeywords: string[]
}

type KeywordItem = {
    value: string
    count: number
    onClick?: () => void
}

type ContextWrapperProps = {
    children: ReactNode
    title: string
    onClose: () => void
    tooltip?: string
}

export const ContentTableContext = ({
    content,
    engagementExpanded,
    keywordsExpanded,
    onClose
}: ContentTableContextProps) => {
    if (!engagementExpanded && !keywordsExpanded) {
        return null;
    }

    return (
        <Col lg="4" className="context-box p-2">
            {(engagementExpanded) && (
                <ContextWrapper onClose={onClose}
                    title="Engagement"
                    tooltip="Our analysis of the engagement of this content so you can see how it is being engaged with."
                >
                    <Engagements content={content} />
                </ContextWrapper>
            )}
            {(keywordsExpanded) && (
                <ContextWrapper onClose={onClose}
                    title="Keywords mentioned"
                    tooltip="Keywords that are mentioned within the content."
                >
                    <Keywords content={content} />
                </ContextWrapper>
            )}
        </Col>
    );
};

const ContextWrapper = ({ children, title, tooltip, onClose }: ContextWrapperProps) => (
    <div className="engagement-context">
        <div className="reach-title d-flex justify-content-between">
            <Row className="reach-title mb-2 pl-2">
                {title}
                {tooltip && (
                    <TooltipItem id="metricsContent"
                        placement="top"
                        content={tooltip}
                    />
                )}
            </Row>
            <X size={25.5} color={color.blue[500]} role="button" onClick={() => onClose()} />
        </div>
        <div>
            {children}
        </div>
    </div>
);

const Keywords = ({ content }: EngagementsProps) => {
    const { setModal } = useModal();
    const location = useLocation();
    const { highlightKeywords } = useSelector((state: {roomPreviewKeywords: RoomPreviewKeywords}) => state.roomPreviewKeywords);
    const passKeywords = location.pathname.includes('create-room') || location.pathname.includes('edit-room')
        || location.pathname.includes('edit-draft-room');
    const { keywordsMentioned } = useQueryExtracts({
        url: content.url,
        text: content.body || content.text,
        keywordsToHighlight: passKeywords ? highlightKeywords : []
    });
    const mostMentioned = [...(keywordsMentioned as KeywordItem[])]?.sort(
        (current, next) => next.count - current.count
    ) || [];
    const openKeywordsModal = () => setModal({
        component: <KeywordsModal keywords={mostMentioned} />
    });
    if (mostMentioned.length === 1) {
        return (
            <div className="mt-2">
                <KeywordMentioned value={mostMentioned[0].value} count={mostMentioned[0].count} />
            </div>
        );
    }

    return (
        <div className="engagement-container">
            {(mostMentioned as KeywordItem[]).slice(0, 6)?.map(({ value, count }) => (
                <KeywordMentioned value={value} count={count} />
            ))}
            {
                mostMentioned.length > 6
                    && <KeywordMentioned value="Others" count={mostMentioned.length - 6} onClick={openKeywordsModal} />
            }
        </div>
    );
};

const KeywordMentioned = ({ value, count, onClick }: KeywordItem) => {
    if (onClick) {
        return (
            <div className="engagement-cards">
                <div>{value}</div>
                <span className="font-weight-bold text-primary cursor-pointer"
                    onClick={(e) => {
                        e.preventDefault();
                        e.stopPropagation();
                        onClick?.();
                    }}
                >
                    {count < 0 ? 'N/A' : count}
                </span>
            </div>
        );
    }
    return (
        <div className="engagement-cards">
            <div>{value}</div>
            <div className="font-weight-bold">{count < 0 ? 'N/A' : count}</div>
        </div>
    );
};

const Engagements = ({ content }: EngagementsProps) => {
    const likesCount = Number.isNaN(Number(content.likesCount)) ? -1 : Number(content.likesCount);
    const repliesCount = Number.isNaN(Number(content.repliesCount)) ? -1 : Number(content.repliesCount);
    const sharesCount = Number.isNaN(Number(content.sharesCount)) ? -1 : Number(content.sharesCount);
    const viewsCount = Number.isNaN(Number(content.viewsCount)) ? -1 : Number(content.viewsCount);
    const retweetsCount = Number.isNaN(Number(content.retweetsCount)) ? -1 : Number(content.retweetsCount);
    if (likesCount === -1 && repliesCount === -1 && sharesCount === -1 && viewsCount === -1) {
        return null;
    }

    return (
        <div className="engagement-container">
            <Engagement title="Likes" count={likesCount} />
            <Engagement title="Replies" count={repliesCount} />
            <Engagement title="Retweets" count={retweetsCount} />
            <Engagement title="Shares" count={sharesCount} />
            <Engagement title="Impressions" count={viewsCount} />
        </div>
    );
};

const Engagement = ({ title, count }: { title: string, count: number }) => (
    <div className="engagement-cards">
        <div>{title}</div>
        <div className="font-weight-bold">{count < 0 ? 'NA' : count}</div>
    </div>
);

import React from 'react';
import { Button, Col, Row } from 'reactstrap';
import { Link, useLocation } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { WatchlistButtonContentKeys } from '../../../pages/Room/Threats/Threat/WatchlistButtonContainer';
import { Content } from '../../../services/Content/contentInterface';
import { useQueryExtracts } from '../../../services/Content/getQueryExtracts';
import { SentimentCard } from './SentimentCard';
import { largeNumber } from '../../../utils/NumberFormat';
import { getRoom } from '../../../utils/variables';
import { WidgetName, WidgetTextTooltip } from '../../../pages/Room/AIClustering/SelectedClusterView/Summary/WidgetTextTooltip';
import { getIcons } from '../../../utils/getIcons';

type RoomPreviewKeywords = {
    highlightKeywords: string[]
}

type ContentTypes = 'body' | 'datetime' | WatchlistButtonContentKeys

type ContentFooterProps = {
    content: Pick<Content, ContentTypes>
    onSentimentClick: () => void
    onKeywordsClick: () => void
    onEngagementClick: () => void
}

export const ContentFooter = ({
    content,
    onSentimentClick,
    onKeywordsClick,
    onEngagementClick
}: ContentFooterProps) => {
    const location = useLocation();
    const room = getRoom();
    const { highlightKeywords } = useSelector((state: {roomPreviewKeywords: RoomPreviewKeywords}) => state.roomPreviewKeywords);
    const passKeywords = location.pathname.includes('create-room') || location.pathname.includes('edit-room')
        || location.pathname.includes('edit-draft-room');

    const { keywordsMentioned } = useQueryExtracts({
        url: content.url,
        text: content.body || content.text,
        keywordsToHighlight: passKeywords ? highlightKeywords : []
    });

    const cardsDisplay = content.sentiment || keywordsMentioned.length > 0 || content.engagement > 0;

    return (
        <Row className={`ml-0 mr-4 justify-content-between ${cardsDisplay ? 'mb-2' : 'no-cards-display float-right'}`}>
            {(cardsDisplay) && (
                <div className="card-group">
                    {content.engagement > 0 && (
                        <Col className="card content-view new-content-view mr-3 px-0 col-lg-4 col-xs-6">
                            <div className="p-1 pl-2">
                                <WidgetTextTooltip widgetName={WidgetName.Engagement} classes="card-text title" />
                                <span className="d-flex align-items-center">{getIcons('engagement')}
                                    <p className="card-text ml-1 m-0 cursor-pointer text-primary" onClick={() => onEngagementClick()}>
                                        {content.engagement === -1 ? 'N/A' : largeNumber(content.engagement)}
                                    </p>
                                </span>
                            </div>
                        </Col>
                    )}
                    <Col className="card content-view new-content-view non-clickable mr-3 px-0 col-lg-4 col-xs-6"
                        onClick={() => onSentimentClick()}
                    >
                        <SentimentCard sentiment={content.sentiment} />
                    </Col>
                    {keywordsMentioned.length > 0 && (
                        <Col className="card content-view new-content-view keywords mr-3 px-0 col-lg-4 col-xs-6">
                            <div className="p-1 pl-2">
                                <WidgetTextTooltip widgetName={WidgetName.KeywordsMentions} classes="card-text title" />
                                <span className="d-flex align-items-center">{getIcons('keywordsMentions')}
                                    <p className="card-text ml-1 cursor-pointer text-primary" onClick={() => onKeywordsClick()}>
                                        {keywordsMentioned.length}
                                    </p>
                                </span>
                            </div>
                        </Col>
                    )}
                </div>
            )}
            <Link target="_blank"
                to={`/situation-rooms/${room.id}/threats/${content.id}`}
                component={Button}
                color="secondary"
                className={`${cardsDisplay ? 'mt-auto' : ''}`}
            >
                View details
            </Link>
        </Row>
    );
};

import React from 'react';
import { Dropdown, DropdownItem, DropdownMenu, DropdownToggle } from 'reactstrap';
import { useHistory } from 'react-router-dom';
import { useModal } from '../../context/modal/ModalComponent';
import { useUser } from '../../context/user/UserContext';
import { useDropDown } from '../../utils/useDropdown';
import { NotThreatIdentifierModal } from '../CommonModals/NotThreatIdentifier';
import { RemoveContentModal } from '../CommonModals/RemoveContent';
import { ThreatIdentifier } from '../CommonModals/ThreatIdentifier';
import { RequestInvestigation } from '../RequestInvestigation';
import { CONTENT_TYPE } from '../../utils/contentTypes';
import { getRoom } from '../../utils/variables';
import { WatchlistButtonContainer } from '../../pages/Room/Threats/Threat/WatchlistButtonContainer';
import { WATCHLIST_BUTTON_TYPES } from '../../pages/Room/Threats/Threat/WatchlistButton';
import { useGetWatchlist } from '../../services/Threat/getWatchlist';
import { ContentTableContentType } from '../Organisms/ContentTableView';

type ThreatLabelProps = {
    threatLevel: string,
    threatLabels: string []
}

type ContentType = {
    id: string,
    threatObject: ThreatLabelProps
}

type ActionsProps = {
    key: string,
    value: React.ReactNode
}

type ContentActionsDropdownProps = {
    content: ContentType,
    pullRight?: boolean,
    isPage?: boolean,
    additionalActions?: ActionsProps []
}

export const ContentActionsDropdown = ({
    content,
    pullRight = false,
    isPage = false,
    additionalActions = []
}: ContentActionsDropdownProps) => {
    const room = getRoom();
    const { notAllowed }: any = useUser();
    const [dropdown, toggleDropdown] = useDropDown();
    const { setModal, closeModal } = useModal();
    const history = useHistory();
    const instancePlan = room.instance?.plan;
    const contentType = content?.threatObject?.threatLevel !== 'none' ? CONTENT_TYPE.THREAT : CONTENT_TYPE.MATCH;
    const { watchlist, refetchWatchlist } = useGetWatchlist({
        situationRoomId: room.id
    });
    const isWatching = watchlist ? watchlist.filter(({ id }: {id: string}) => id === content.id).length > 0 : false;

    if (notAllowed.includes('editData') || !instancePlan?.others?.contentActions) return null;

    const setRequestInvestigationModal = () => setModal({
        modal: <RequestInvestigation defaultDetails={content} onClose={closeModal} />,
        goToPreviousOnClose: true
    });

    const setMarkAsThreatModal = () => setModal({
        modal: <ThreatIdentifier onClose={closeModal} threatId={content.id} />,
        goToPreviousOnClose: true
    });

    const handleOnContentRemove = () => {
        if (contentType === CONTENT_TYPE.THREAT) {
            return history.push(`/situation-rooms/${room.id}/threats`);
        }

        return history.push(`/situation-rooms/${room.id}/overview/matches`);
    };

    const setRemoveContentModal = () => {
        setModal({
            modal: <RemoveContentModal removeData={[{ id: content.id }]} type={contentType} onCompleted={handleOnContentRemove} />,
            goToPreviousOnClose: true
        });
    };
    const setNotThreatIdentifierModal = () => {
        setModal({
            modal: <NotThreatIdentifierModal ids={[content.id]} />,
            goToPreviousOnClose: true
        });
    };
    return (
        <span className={`${pullRight && 'float-right ml-auto'}`}>
            <Dropdown onClick={(e) => { e.preventDefault(); e.stopPropagation(); }}
                className="d-inline"
                isOpen={dropdown}
                toggle={toggleDropdown}
                tag="div"
                direction={window.innerWidth < 540 ? 'left' : 'right'}
                data-testid="content-list-item-actions"
            >
                <DropdownToggle className="bg-transparent border-0 mw-0 mr-n1 my-n1" data-testid="content-list-item-actions-toggle">
                    <span><i className="fa fa-ellipsis-v text-secondary" /></span>
                </DropdownToggle>
                <DropdownMenu>
                    {content.threatObject?.threatLevel !== 'none' ? (
                        <>
                            <DropdownItem onClick={setRequestInvestigationModal}>
                                <span>Investigate</span>
                            </DropdownItem>
                            <DropdownItem onClick={setNotThreatIdentifierModal}>
                                <span>Mark as not threat</span>
                            </DropdownItem>
                            {isPage && (
                                <DropdownItem onClick={() => null}>
                                    <WatchlistButtonContainer contents={[content as ContentTableContentType]}
                                        isWatching={isWatching}
                                        onCompleted={refetchWatchlist}
                                        type={WATCHLIST_BUTTON_TYPES.TEXT}
                                    />
                                </DropdownItem>
                            )}
                        </>
                    ) : (
                        <DropdownItem onClick={setMarkAsThreatModal}>
                            <span>Mark as threat</span>
                        </DropdownItem>
                    )}
                    <DropdownItem onClick={setRemoveContentModal}>
                        <span>Remove content</span>
                    </DropdownItem>
                    {additionalActions.map(action => (
                        <DropdownItem key={action.key}>
                            {action.value}
                        </DropdownItem>
                    ))}
                </DropdownMenu>
            </Dropdown>
        </span>
    );
};

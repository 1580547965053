import { DATE_RANGE_LABEL, DATE_RANGE_VALUE } from '../getTimeFrom';

const dateFilters = [
    {
        key: DATE_RANGE_VALUE.all,
        label: DATE_RANGE_LABEL.all,
        id: 'filter-time-alltime1'
    },
    {
        key: DATE_RANGE_VALUE.day,
        label: DATE_RANGE_LABEL.day,
        id: 'filter-time-last24hrs1'
    },
    {
        key: DATE_RANGE_VALUE.week,
        label: DATE_RANGE_LABEL.week,
        id: 'filter-time-lastweek1'
    },
    {
        key: DATE_RANGE_VALUE.custom,
        label: DATE_RANGE_LABEL.custom,
        id: 'filter-time-Customdate1'
    }
];

const sentiments = [
    {
        key: 'positive',
        label: 'Positive'
    },
    {
        key: 'neutral',
        label: 'Neutral'
    },
    {
        key: 'negative',
        label: 'Negative'
    }
];

const allTypes = [
    { name: 'Automated account', key: 'automation_score' },
    { name: 'Custom threat', key: 'custom' },
    { name: 'Source credibility', key: 'src-cred' },
    { name: 'Domestic extremism', key: 'domestic_extremism' },
    { name: 'Insulting language', key: 'INSULT', isResolver: true },
    { name: 'Toxic language', key: 'TOXICITY', isResolver: true },
    { name: 'Severely toxic language', key: 'SEVERE_TOXICITY', isResolver: true },
    { name: 'Threatening language', key: 'THREAT', isResolver: true },
    { name: 'Attack on identity', key: 'IDENTITY_ATTACK', isResolver: true },
    { name: 'Inflammatory language', key: 'INFLAMMATORY', isResolver: true },
    { name: 'Attack on author', key: 'ATTACK_ON_AUTHOR', isResolver: true },
    { name: 'Attack on commenter', key: 'ATTACK_ON_COMMENTER', isResolver: true }
];

export {
    allTypes,
    sentiments,
    dateFilters
};

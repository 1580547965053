import React, { useState, useRef } from 'react';
import { ChevronRight } from 'react-feather';
import { ActivityItem } from '../../../../components/ActivityItem/ActivityItem';
import { Loading } from '../../../../components/Loading';
import { SmallPagination } from '../../../../components/Molecules';
import { TooltipItem } from '../../../../components/ToolTip';
import { usePreviewData } from './usePreviewData';
import { useModal } from '../../../../context/modal/ModalComponent';
import { ContentPreviewModal } from '../../../Room/Overview/ContentModal/contentPreviewModal';
import { CONTENT_TYPE } from '../../../../utils/contentTypes';
import { color } from '../../../../utils/getColors';

export const Matches = ({ keywordsToHighlight }) => {
    const [page, setPage] = useState(0);
    const { setModal } = useModal();
    const itemsPerPage = 5;
    const { loading, data: contents, count } = usePreviewData({
        limit: itemsPerPage,
        offset: page * itemsPerPage
    });
    const matchesRef = useRef(null);
    return (
        <div className="bg-white p-3 h-100 d-flex flex-column" ref={matchesRef}>
            <div>
                <div className="d-flex align-items-center">
                    <h3 className="pr-1 m-0">Matches</h3>
                    <TooltipItem id="matchesFeed"
                        placement="top"
                        content="New content matches will appear in your feed chronologically as they are detected and analysed."
                    />
                    <div role="button"
                        className="d-flex align-items-center ml-auto"
                        onClick={() => setModal({
                            header: 'Matches',
                            component: <ContentPreviewModal contentType={CONTENT_TYPE.MATCH} preview />,
                            size: 'xl',
                            keepOpen: true
                        })}
                    >
                        <p className="m-0 text-primary">See more</p>
                        <ChevronRight color={color.blue[500]} size={18} />
                    </div>
                </div>
                <hr className="my-3" />
                {loading && <Loading relative height={200} />}
            </div>
            {!loading && contents.length > 0 && (
                <div className="flex-1 d-flex flex-column justify-content-between">
                    <div>
                        {contents.map(content => {
                            let highlights = content.highlight?.text || [];
                            if (content.highlight?.body?.length > highlights.length) { highlights = content.highlight.body; }
                            return (
                                <div key={content.id}>
                                    <ActivityItem content={content}
                                        preview
                                        keywordsToHighlight={keywordsToHighlight}
                                    />
                                </div>
                            );
                        })}
                    </div>
                    <div className="d-flex justify-content-end">
                        <SmallPagination page={page}
                            setPage={(p) => {
                                setPage(p);
                                document.getElementById('right-panel')?.scrollTo({ top: matchesRef.current?.offsetTop || 0 });
                            }}
                            total={count}
                            itemsPerPage={itemsPerPage}
                        />
                    </div>
                </div>
            )}
        </div>
    );
};

import React from 'react';
import { Link } from 'react-router-dom';
import { DropdownItem, DropdownMenu, DropdownToggle, UncontrolledDropdown } from 'reactstrap';
import { MoreVertical } from 'react-feather';
import moment from 'moment';
import { useDispatch } from 'react-redux';
import { useMarkAsRead } from '../../../services/Alerts/useMarkAsRead';
import { NotificationSummary } from './NotificationSummary';
import { saveFilters } from '../../../pages/Room/store';

type AlertSituation = {
    id: string,
    name: string
   }

type NotificationDefaultProps = {
    id: string,
    projectId: string,
    date: number,
    category: string,
    subcategory: string,
    description: string,
    situationRoom: AlertSituation,
    read: boolean
};

type NotificationProps = {
    notification: NotificationDefaultProps
    showUnread?: boolean
}

export const NotificationCard = ({ notification, showUnread }: NotificationProps) => {
    const { markAsRead } = useMarkAsRead({ alertIds: [notification.id], showUnread });

    const dispatch = useDispatch();
    const endDate = notification.date / 1000;
    const startDate = endDate - (7 * 24 * 60 * 60);

    const setDateRange = () => {
        dispatch(saveFilters({
            dateRange: {
                startDate,
                endDate
            }
        }));
    };

    return (
        <div className="dropdown-item p-2 mb-1 d-flex noti-page rounded"
            data-testid={`notification-item-${notification.projectId}`}
        >
            <div className="mr-2">
                <p className="noti-avatar">LI</p>
            </div>
            <div>
                <p className="text-wrap">
                    <NotificationSummary
                        category={notification.category}
                        subcategory={notification.subcategory}
                        description={notification.description}
                    />
                </p>
                <Link to={`/situation-rooms/${notification.situationRoom.id}/overview?daterange=${startDate},${endDate}`}
                    onClick={setDateRange}
                >
                    <p className="text-primary d-inline font-size-14">
                        {notification.situationRoom.name}
                    </p>&nbsp;
                </Link>
                <p className="d-inline font-size-14">
                    | {moment(notification.date).format('DD-MM-YYYY')}
                </p>
                {!notification.read
                        && <span className="unread ml-1" />}
            </div>
            {!notification.read
                && (
                    <UncontrolledDropdown direction="left" className="mw-0 mr-0 menu-hide ml-auto">
                        <DropdownToggle tag="div">
                            <MoreVertical size={24} role="button" className="text-primary d-block" />
                        </DropdownToggle>
                        <DropdownMenu>
                            <DropdownItem onClick={() => markAsRead()} className="mw-0 px-2 py-1 rounded">
                                Mark as read
                            </DropdownItem>
                        </DropdownMenu>
                    </UncontrolledDropdown>
                )}
        </div>
    );
};

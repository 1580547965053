import { useMutation } from '@apollo/client';
import { gql } from '../../generated';

type UseMarkAsReadProps = {
    alertIds: string[];
    markAllAsRead?: boolean;
    showUnread?: boolean;
};

export const useMarkAsRead = ({ alertIds, markAllAsRead, showUnread }: UseMarkAsReadProps) => {
    const [markAsRead, { data, loading }] = useMutation(MARK_AS_READ, {
        variables: {
            alertIds,
            markAllAsRead
        },
        update: (cache) => {
            alertIds.forEach((alertId) => {
                if (showUnread) {
                    cache.evict({
                        id: cache.identify({
                            __typename: 'Alert',
                            id: alertId
                        })
                    });
                } else {
                    cache.modify({
                        id: cache.identify({
                            __typename: 'Alert',
                            id: alertId
                        }),
                        fields: {
                            read: () => true
                        }
                    });
                }
            });
        },
        refetchQueries: ['getAllAlerts']
    });

    return {
        loading,
        markAsRead,
        done: data?.markAsRead
    };
};

export const MARK_AS_READ = gql(`
    mutation markAsRead($alertIds: [String]!, $markAllAsRead: Boolean) {
        markAsRead(alertIds: $alertIds, markAllAsRead: $markAllAsRead)
    }
`);

import React from 'react';
import { useContent } from '../../../../../services/Content/getContent';
import { Loading } from '../../../../../components/Loading';
import { ActivityItem } from '../../../../../components/ActivityItem/ActivityItem';

type OriginalPosterProps = {
    originatorPostId: string
}

export const OriginalPoster = ({ originatorPostId }: OriginalPosterProps) => {
    const { loading, data: content } = useContent({
        filters: {
            ids: originatorPostId
        },
        fetchPolicy: 'no-cache',
        name: 'getClusterContent'
    });
    if (loading) {
        return <Loading relative height={400} background="white" />;
    }
    return (
        <div className="bg-white p-2">
            <div className="border px-2 pt-1 pb-2">
                <ActivityItem content={content[0]} isCluster />
            </div>
        </div>
    );
};

import React, { useState } from 'react';
import { ChevronDown } from 'react-feather';
import { Button, DropdownItem, Popover, PopoverBody } from 'reactstrap';
import { pick } from 'lodash';
import { useDispatch } from 'react-redux';
import { SavedNetwork, useSavedNetworks } from './useSavedNetworks';
import { SearchInput } from '../../../../../components/Molecules';
import { formatDate } from '../../../../../utils/getTimeFrom';
import { removeTypename } from '../../../../../utils/removeAllTypename';
import { useNetworkHistory } from '../../NetworksChart/handlers';
import { Loading } from '../../../../../components/Loading';
import { useModal } from '../../../../../context/modal/ModalComponent';
import { ManageSavedNetworks } from './ManageSavedNetworks';
import { NetworksHistoryState } from '../../store';
import { saveFilters, saveSelectedFilters } from '../../../store';
import { getRoom } from '../../../../../utils/variables';

export const SavedNetworks = ({ onOpen }: { onOpen: () => void }) => {
    const [isOpen, setOpen] = useState(false);
    const room = getRoom();
    const [search, setSearch] = useState('');
    const togglePopover = () => {
        if (isOpen) {
            setSearch('');
        } else {
            onOpen();
        }
        setOpen(!isOpen);
    };
    const dispatch = useDispatch();
    const { setModal } = useModal();
    const { savedNetworks, loading } = useSavedNetworks({ search });
    const networkHistory = useNetworkHistory();
    const handleSelectNetwork = (network: SavedNetwork) => {
        if (network.filters) {
            dispatch(saveFilters({ ...network.filters, roomId: room?.id }));
            dispatch(saveSelectedFilters(network.filters));
        }
        // format for redux store
        const networkData = removeTypename({
            ...(pick(network, ['filters', 'deletedNodes', 'layout', 'includeAuthorsAndMentions', 'includeHashtags',
                'includeSharedURLs', 'minimumNodeConnections', 'sizeNodesBy', 'clusterBy'])),
            chartState: {
                why: 'user',
                positions: network.chartState.positions ? Object.fromEntries(
                    network.chartState.positions.map(({ id, x, y }) => ([id, { x, y }]))
                ) : {}
            }
        }) as NetworksHistoryState;
        setOpen(false);
        networkHistory.reset(networkData);
    };
    const openManageSavedNetworksModal = () => {
        setOpen(false);
        setSearch('');
        setModal({
            header: 'Saved networks',
            size: 'lg',
            component: <ManageSavedNetworks />
        });
    };

    return (
        <div>
            <Button id="savedNetworks">
                Saved networks
                <ChevronDown className={`ml-11 transform-transition ${isOpen ? 'transform-btn-icon' : ''}`} />
            </Button>
            <Popover isOpen={isOpen} toggle={togglePopover} placement="bottom-end" target="savedNetworks" trigger="legacy">
                <PopoverBody className="p-0" data-testid="saved-networks-popover">
                    <div className="p-2">
                        <SearchInput placeholder="Search" handleSearch={setSearch} className="mb-n1" />
                    </div>
                    <div className="border-top border-bottom py-1 maxh-350 overflow-y-auto scrollbar-small minw-400">
                        {!savedNetworks.length ? (
                            <div>
                                {loading ? <Loading relative height={200} /> : <p className="px-3 py-1 m-0">No results</p>}
                            </div>
                        ) : savedNetworks.map((network, i) => (
                            <DropdownItem key={network.id}
                                className={`${i === savedNetworks.length - 1 ? '' : 'mb-1'} py-1 px-2`}
                                toggle={false}
                                onClick={() => handleSelectNetwork(network)}
                            >
                                <p className="mb-11"><strong>{network.name}</strong></p>
                                <p className="m-0">
                                    Saved by: {network.createdBy.displayName} | {formatDate(network.createdAt, 'DD/MM/YYYY @ HH:mm:ss z')}
                                </p>
                            </DropdownItem>
                        ))}
                    </div>
                    <div className="p-2">
                        <Button color="link" className="p-0" onClick={openManageSavedNetworksModal}>Manage saved networks</Button>
                    </div>
                </PopoverBody>
            </Popover>
        </div>
    );
};

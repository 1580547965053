import React, { useState } from 'react';
import { CustomInput, Row, Col } from 'reactstrap';
import { useQuery } from '@apollo/client';
import { ExternalLink } from 'react-feather';
import { Content } from './Content';
import { ContentTableContentType } from './index';
import { getAllLabels, ThreatLabels } from './ThreatLabels';
import { ContentActionsDropdown } from './ContentActionsDropdown';
import { ContentTableContext } from './ContentTableContext';
import { GET_CONTENT_LABELS, LabelDropdown } from '../../../pages/Room/Labels';
import { getRoom } from '../../../utils/variables';
import { TextTooltip } from '../../ToolTip/TextTooltip';
import { ContentFooter } from './ContentFooter';
import { ExternalLinkButton } from '../../LinkModal/ExternalLinkButton';
import { useGetWatchlist } from '../../../services/Threat/getWatchlist';
import { WatchlistButtonContainer } from '../../../pages/Room/Threats/Threat/WatchlistButtonContainer';
import { WATCHLIST_BUTTON_TYPES } from '../../../pages/Room/Threats/Threat/WatchlistButton';
import { ContentId } from '../../../pages/Room/AIClustering/types';

type ContentTableViewItemProps = {
    content: ContentTableContentType
    selectedContentItems: ContentTableContentType[]
    noActionsAllowed: boolean
    onSetSelectedContentItems: (values: ContentTableContentType[]) => void
    onRemoveContent?: (data: { id: string }[]) => void
    onMarkAsThreat?: (id: string) => void
    onMarkAsNotThreat?: (ids: string[]) => void
    onRequestInvestigation?: (content: ContentTableContentType) => void
    clusterData?: ContentId[]
}

export const ContentTableViewItem = ({
    content,
    selectedContentItems,
    onSetSelectedContentItems,
    noActionsAllowed,
    onRemoveContent,
    onMarkAsThreat,
    onMarkAsNotThreat,
    onRequestInvestigation,
    clusterData
}: ContentTableViewItemProps) => {
    const room = getRoom();
    const [textLimit, setTextLimit] = useState(300);
    const [sentimentExpanded, setSentimentExpanded] = useState(false);
    const [keywordsExpanded, setKeywordsExpanded] = useState(false);
    const [engagementExpanded, setEngagementExpanded] = useState(false);
    const checked = selectedContentItems.map(x => x.id).indexOf(content.id) === -1;

    const { watchlist, refetchWatchlist } = useGetWatchlist({
        situationRoomId: room.id
    });
    const isWatching = watchlist ? watchlist.filter(({ id }: {id: string}) => id === content.id).length > 0 : false;

    const handleTextLimitChange = (value: number) => {
        setTextLimit(value);
    };

    const handleCheckboxChange = () => {
        if (!checked) {
            return onSetSelectedContentItems(
                selectedContentItems?.filter(selectedContentItem => selectedContentItem.id !== content.id)
            );
        }

        return onSetSelectedContentItems([
            ...selectedContentItems,
            content
        ]);
    };

    const handleMetricsClick = (type: string) => {
        switch (type) {
            case 'sentiment':
                setSentimentExpanded(!sentimentExpanded);
                setKeywordsExpanded(false);
                setEngagementExpanded(false);
                break;
            case 'keywords':
                setSentimentExpanded(false);
                setKeywordsExpanded(!keywordsExpanded);
                setEngagementExpanded(false);
                break;
            case 'engagement':
                setSentimentExpanded(false);
                setKeywordsExpanded(false);
                setEngagementExpanded(!engagementExpanded);
                break;
            case 'closeAll':
                setSentimentExpanded(false);
                setKeywordsExpanded(false);
                setEngagementExpanded(false);
                break;
        }
    };

    const { data } = useQuery(GET_CONTENT_LABELS, {
        variables: {
            situationRoom: room?.id
        },
        fetchPolicy: 'network-only',
        skip: !(room?.instance?.plan?.others?.labels)
    });

    const allLabels = getAllLabels({
        contentId: content.id,
        userLabelsData: data?.getContentLabels || [],
        threatResolvers: content.threatObject.threatResolvers || []
    });

    if (!content) {
        return null;
    }
    return (
        <tr key={content.id}>
            {!noActionsAllowed && (
                <td className="pr-0 align-top">
                    <CustomInput type="checkbox"
                        checked={!checked}
                        id={`contentTableViewItem-${content.id}`}
                        onChange={handleCheckboxChange}
                    />
                </td>
            )}
            <td className="p-0 align-top">
                <Row className="p-0 m-0">
                    <Col className="p-0">
                        <Row className="mt-2 mr-0">
                            <Col lg="8" className="p-0">
                                <ThreatLabels content={content} />
                            </Col>
                            <Col lg="4" className="p-0 pr-1">
                                <div className="d-flex justify-content-end align-items-center content-top-bar">
                                    {content.url && (
                                        <TextTooltip placement="top"
                                            title="Open source"
                                            id={`content-${content.id}-link`}
                                            withIcon
                                            className="mt-11 mr-11"
                                        >
                                            <ExternalLinkButton url={content.url}>
                                                <ExternalLink size={24} className="mr-1" />
                                            </ExternalLinkButton>
                                        </TextTooltip>
                                    )}
                                    <TextTooltip placement="top"
                                        title="Labels"
                                        id={`content-${content.id}-labels`}
                                        withIcon
                                        className="mt-11"
                                    >
                                        <LabelDropdown contentId={content.id} roomId={room?.id} />
                                    </TextTooltip>
                                    {content.threatObject?.threatLevel !== 'none' ? (
                                        <TextTooltip placement="top"
                                            title={isWatching ? 'Remove to watchlist' : 'Add to watchlist'}
                                            id={`content-${content.id}-watchlist`}
                                            className="ml-2"
                                            withIcon
                                        >
                                            <WatchlistButtonContainer contents={[content]}
                                                isWatching={isWatching}
                                                onCompleted={refetchWatchlist}
                                                type={WATCHLIST_BUTTON_TYPES.TEXT_BUTTON}
                                                showText={false}
                                            />
                                        </TextTooltip>
                                    ) : null }
                                    {(!noActionsAllowed) && (
                                        <TextTooltip placement="top"
                                            title="Menu"
                                            id={`content-${content.id}-menu`}
                                            withIcon
                                        >
                                            <ContentActionsDropdown content={content}
                                                onRemoveContent={onRemoveContent}
                                                onMarkAsThreat={onMarkAsThreat}
                                                onMarkAsNotThreat={onMarkAsNotThreat}
                                                onRequestInvestigation={onRequestInvestigation}
                                            />
                                        </TextTooltip>
                                    )}
                                </div>
                            </Col>
                        </Row>
                        <Row className={`ml-0 mr-0 mb-2 ${allLabels.length === 0 && 'no-labels-header'}`}>
                            <div className="col col-lg-8 p-0">
                                <Content content={content}
                                    textLimit={textLimit}
                                    onReadMoreClick={() => handleTextLimitChange(999999)}
                                    onReadLessClick={() => handleTextLimitChange(300)}
                                    clusterData={clusterData}
                                />
                            </div>
                            <div className="col col-lg-4 p-0" />
                        </Row>
                        <ContentFooter content={content}
                            onSentimentClick={() => handleMetricsClick('')}
                            onKeywordsClick={() => handleMetricsClick('keywords')}
                            onEngagementClick={() => handleMetricsClick('engagement')}
                        />
                    </Col>
                    <ContentTableContext content={content}
                        engagementExpanded={engagementExpanded}
                        keywordsExpanded={keywordsExpanded}
                        onClose={() => handleMetricsClick('closeAll')}
                    />
                </Row>
            </td>
        </tr>
    );
};

import React, { useState, useImperativeHandle, forwardRef, useEffect } from 'react';
import {
    Dropdown,
    DropdownToggle,
    DropdownMenu,
    Label,
    CustomInput,
    Input,
    FormGroup
} from 'reactstrap';
import * as FeatherIcon from 'react-feather';
import { SelectAllCheckBox } from './CheckBox';
import { capitalizeFirstLetter } from '../../utils/text';
import { useDropDown } from '../../utils/useDropdown';
import { color as allColors } from '../../utils/getColors';

type OptionProps = {
    id: string,
    text?: string,
}

type MultiSelectDropdownProps = {
    label?: string,
    id: string,
    options: OptionProps[],
    defaultOptions?: OptionProps[],
    className?: string,
    search?: boolean,
    btnBlock?: string,
    color?: string,
    onChange?: (e: OptionProps[]) => void,
    maxCount?: number,
    position?: boolean,
    direction?: 'up' | 'down' | 'left' | 'right'
}

export const MultiSelectDropdown = forwardRef(({
    label = '',
    id,
    options,
    defaultOptions,
    className,
    search = false,
    btnBlock = '',
    color,
    onChange,
    maxCount = 0,
    position,
    direction = 'down'
}: MultiSelectDropdownProps, ref) => {
    const [selected, setSelected] = useState(defaultOptions || []);
    const [searchVal, setSearchVal] = useState('');
    const [dropdown, toggleDropdown] = useDropDown();
    useImperativeHandle(ref, () => ({
        getSelected: () => selected,
        reset: () => setSelected(options)
    }));
    useEffect(() => {
        if (onChange) {
            onChange(selected);
        }
    }, [selected]); // eslint-disable-line react-hooks/exhaustive-deps

    return (
        <div className={className || ''}>
            {label && <Label>{label}</Label>}
            <Dropdown className={btnBlock}
                isOpen={dropdown as boolean}
                toggle={toggleDropdown as React.MouseEventHandler<any>}
                direction={direction}
            >
                <DropdownToggle tag="div"
                    className="form-control d-flex justify-content-between"
                    role="button"
                    data-testid="dropdown-toggle"
                >
                    {selected.length !== 0
                        ? (
                            <div className="mx-1">
                                {selected.length > 1 ? (
                                    <span>
                                        {selected.length === options.length
                                            ? 'All selected'
                                            : `${selected.length} Option${selected.length !== 1 ? 's' : ''} selected`}
                                    </span>
                                ) : selected[0]?.text}
                            </div>
                        )
                        : 'None selected'}
                    <span className="float-right">
                        <FeatherIcon.ChevronDown color={color || allColors.blue[500]} size={20} />
                    </span>
                </DropdownToggle>
                <DropdownMenu className="maxh-300 scrollbar-small overflow-y-auto overflow-x-hidden py-1" right={!!position}>
                    {search && (
                        <Input placeholder="search"
                            value={searchVal}
                            onChange={(e) => {
                                setSearchVal(e.target.value);
                            }}
                            className="border-0"
                            data-testid="user-search-input"
                        />
                    )}
                    <SelectAllCheckBox id={`multiSelect${id || label.replace(/ /g, '')}`}
                        values={selected}
                        options={options}
                        allCheck={(data) => setSelected(data)}
                        maxCount={maxCount}
                    >
                        {options.filter(
                            a => (a.id.includes(searchVal.toLowerCase()) || a.text?.toLowerCase().includes(searchVal.toLowerCase()))
                        ).map((item, index) => {
                            const checked = !!selected.find(a => a.id === item.id);
                            const disabled = maxCount && selected.length >= maxCount && !checked;
                            return (
                                <FormGroup key={item.id} className="w-100">
                                    <CustomInput type="checkbox"
                                        checked={checked}
                                        disabled={disabled === 0 ? false : disabled}
                                        className="custom-control-input-big no-white-space pr-2"
                                        label={capitalizeFirstLetter(item.text)}
                                        id={`${item.id.replace(/ /g, '-')}-${index}-${id || label.replace(/ /g, '')}`}
                                        data-testid={`custom-control-input-${item.id}-${index}`}
                                        onChange={(e) => {
                                            e.stopPropagation();
                                            if (checked) {
                                                setSelected(selected.filter(a => a.id !== item.id));
                                            } else {
                                                setSelected([...selected, item]);
                                            }
                                        }}
                                    />
                                </FormGroup>
                            );
                        })}
                    </SelectAllCheckBox>
                </DropdownMenu>
            </Dropdown>
        </div>
    );
});
